body {
  margin: 0;
  background: #f0f2f5;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

*,
::after,
::before {
  box-sizing: border-box;
}

.background-sky {
  background: linear-gradient(145deg,#234464,#21466b);
}

.home-row {
  max-width: 920px;
  margin: 32px auto;
}

.sky {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 480px;
  height: 80vh;
  max-height: 160px;
  box-sizing: border-box;
  padding: 48px 48px 32px;
  overflow: hidden;
  transform: skewY(-8deg);
  transform-origin: 0%;
}

section#intro {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 900px;
  width: 100%;
  height: 480px;
  margin: 0 auto;
  padding: 48px 0 0;
}

section#intro .sky-logo {
  display: flex;
  width: 50%;
  min-width: 250px;
  height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 8px;
}

@media (max-width: 65rem) {
  section#intro {
      flex-direction: column;
      justify-content: center;
      padding: 40px 0 32px;
  }
  .sky{
    min-height: 280px;
    max-height: 320px;
  }
}

select {
  word-wrap: normal;
}

button,
select {
  text-transform: none;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

:root {
  --Hg-Navbar: 40px;
  --Primmary-color: #234464;
  --Second-color: #8D164B;
  --Hg-Content: auto;
  --Open-nav: inline-block;
  --Con-tent: 270px;
  --Paginate: 350px;
  --Nav-subs: none;
}

.cont-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-atri {
  height: 100px;
  width: 100px;
}

.center-objects {
  display: flex;
  align-items: center;
}

.column-direction {
  flex-direction: column;
}

.hg-23 {
  height: 23px;
}

.hg-36 {
  height: 36px;
}

.hg-46 {
  height: 46px;
}

.max-hg_40 {
  max-height: 40px;
}

.max-wd_40 {
  max-width: 40px;
}

.max-wd_50p {
  max-width: 50%;
}


.max-wd_100p {
  max-width: 100%;
}

.cenetr-items {
  display: flex;
  justify-content: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-none {
  align-items: normal !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ft-family-monospace {
  font-family: monospace;
}

.ft-family-pre {
  font-family: source-code-pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;

}

.ft-weight_bold {
  font-weight: bold;
}

.itm-center {
  align-items: center;
}

.dp-none {
  display: none;
}

.dp-flex {
  display: flex;
}

.dp-flex-imp {
  display: flex !important;
}

.dp-grid {
  display: grid;
}

.dp-block {
  display: block !important;
}

.bottom-0 {
  bottom: 0;
}

.list-none {
  list-style: none;
}

.ft-size_8 {
  font-size: 8px;
}

.ft-size_10 {
  font-size: 10px;
}

.ft-size_11 {
  font-size: 11px;
}

.ft-size_12 {
  font-size: 12px !important;
}

.ft-size_13 {
  font-size: 13px;
}

.ft-size_14 {
  font-size: 14px;
}

.ft-size_15 {
  font-size: 15px;
}

.ft-size_16 {
  font-size: 16px;
}

.ft-size_20 {
  font-size: 20px;
}

.ft-size_25 {
  font-size: 25px;
}

.ft-size_28 {
  font-size: 28px;
}

.ft-size_50 {
  font-size: 50px;
}

.weight-bold {
  font-weight: bold;
}

.opacity-05 {
  opacity: .5;
}

.color-white {
  color: #fff !important;
}

.color-lavel {
  color: #BFC7D5 !important;
}

.color-black {
  color: #000;
}

.color-gray {
  color: gray !important;
}

.color-5f6368 {
  color: #5f6368;
}

.color-3dd0f8 {
  color: #3dd0f8;
}

.color-FFCB8B {
  color: #FFCB8B;
}

.color-FF5572 {
  color: #FF5572;
}

.color-C3E88D {
  color: #C3E88D;
}

.color-C792EA {
  color: #C792EA;
}

.color-BEC5D4 {
  color: #BEC5D4;
}

.color-82AAFF {
  color: #82AAFF;
}

.color-89DDFF {
  color: #89DDFF;
}

.color-blue {
  color: blue;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

.color-err {
  color: #d13333;
}

.color-cstm {
  color: hsl(0, 0%, 100%, .6);
}

.bg-navbar {
  background: #234464 !important;
}

.bg-primary {
  background-color: var(--Primmary-color);
}

.bg-second {
  background: var(--Second-color);
}

.bg_modal{
  background-color: #252A37 !important;
}

.bg-white {
  background-color: #fff ;
}

.bg-red {
  background-color: red;
}

.bg-gray {
  background: gray;
}

.bg-green {
  background: green;
}

.bg-brown {
  background: brown;
}

.bg-sandy {
  background: sandybrown;
}

.bg-teal {
  background: teal;
}

.bg-1B74E4 {
  background: #1B74E4;
}

.bg-C792EA {
  background: #C792EA;
}

.bg-82AAFF {
  background: #82AAFF;
}

.bg-f1f3f4 {
  background-color: #f1f3f4;
}

.bg-232f3e {
  background: #232f3e;
}

.bg-0299c6 {
  background: #0299c6;
}

.bg-EC7211 {
  background: #EC7211;
}

.bg-0 {
  background: #f0ad4e;
}

.bg-1 {
  background: #4bafea;
}

.bg-3 {
  background: #dc3545;
}

.bg-4 {
  background: #449d44;
}

.pd-0 {
  padding: 0 !important;
}

.pd-0_15 {
  padding: 0 15px;
}

.pd-0_20 {
  padding: 0 20px;
}

.pd-5_20 {
  padding: 5px 20px;
}

.pd-5_10 {
  padding: 5px 10px;
}

.pd-6_8 {
  padding: 6px 8px;
}

.pd-10_0 {
  padding: 10px 0;
}

.pd-30_20 {
  padding: 30px 20px;
}

.pd-5 {
  padding: 5px;
}

.pd-7 {
  padding: 7px !important;
}

.pd-8 {
  padding: 8px !important;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-30 {
  padding: 30px;
}

.pd-left_4 {
  padding-left: 4px;
}

.pd-left_5 {
  padding-left: 5px;
}

.pd-left_7 {
  padding-left: 7px !important;
}

.pd-left_10 {
  padding-left: 10px;
}

.pd-left_15 {
  padding-left: 15px;
}

.pd-left_20 {
  padding-left: 20px;
}

.pd-right_5 {
  padding-right: 5px;
}

.pd-right_10 {
  padding-right: 10px;
}

.pd-right_20 {
  padding-right: 20px;
}

.pd-right_40 {
  padding-right: 40px !important;
}

.pd-top_5 {
  padding-top: 5px;
}

.pd-top_10 {
  padding-top: 10px;
}

.pd-top_20 {
  padding-top: 20px;
}

.pd-top_50 {
  padding-top: 50px;
}

.pd-top_100 {
  padding-top: 100px;
}

.pd-bottom_10 {
  padding-bottom: 10px;
}

.wd-45 {
  width: 45px;
}

.wd-320 {
  width: 320px;
}

.wd-500 {
  width: 500px;
}

.wd-600 {
  width: 600px;
}

.wd-50p {
  width: 50%;
}

.wd-90p {
  width: 90%;
}

.wd-100p {
  width: 100%;
}

.tx-center {
  text-align: center;
}

.tx-center_webkit {
  text-align: -webkit-center;
}

.tx-left {
  text-align: left;
}

.tx-right {
  text-align: right;
}

.mg-auto {
  margin: auto
}

.mg-0 {
  margin: 0 !important;
}

.mg-0_10 {
  margin: 0 10px;
}

.mg-8 {
  margin: 8px;
}

.mg-10 {
  margin: 10px;
}

.mg-20 {
  margin: 20px;
}

.mg-right_auto {
  margin-right: auto;
}

.mg-left_auto {
  margin-left: auto;
}

.mg-top_1 {
  margin-top: 1px;
}

.mg-top_7 {
  margin-top: 7px;
}

.mg-top_10 {
  margin-top: 10px;
}

.mg-top_20 {
  margin-top: 20px;
}

.mg-bottom_7 {
  margin-bottom: 7px;
}

.mg-bottom_10 {
  margin-bottom: 10px;
}

.mg-bottom_15 {
  margin-bottom: 15px;
}

.mg-bottom_20 {
  margin-bottom: 20px;
}

.mg-bottom_24 {
  margin-bottom: 24px;
}

.mg-bottom_50 {
  margin-bottom: 50px;
}

.mg-bottom_100 {
  margin-bottom: 100px;
}

.mg-left_10 {
  margin-left: 10px;
}

.mg-left_15 {
  margin-left: 15px;
}

.mg-left_16 {
  margin-left: 16px;
}

.mg-left_20 {
  margin-left: 20px;
}

.mg-left-15 {
  margin-left: -15px;
}

.mg-left_50 {
  margin-left: 50px;
}

.mg-right_10 {
  margin-right: 10px;
}

.mg-right_20 {
  margin-right: 20px;
}

.mg-right_30 {
  margin-right: 30px;
}

.outline-none {
  outline: none;
}

.select-none {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.dg-nowrap {
  white-space: nowrap;
}

.bd-none {
  border: none;
}

.bd_solid1_BEC5D4 {
  border: 1px solid #BEC5D4;
}

.bd_solid1_4bafea {
  border: 1px solid #4bafea;
}

.bd_solid1_fd3a5a {
  border: 1px solid #fd3a5a;
}

.bd_solid1_7ab620 {
  border: 1px solid #7ab620;
}

.bd-solid_dadce0 {
  border: 1px solid #dadce0;
}

.bd-dashed_050505 {
  border: 1px dashed #050505;
}

.bd-left_solid_f1 {
  border-left: 1px solid #f1f1f1;
}

.bd-right_solid_f1 {
  border-right: 1px solid #f1f1f1;
}

.bd-bottom_solid_f1 {
  border-bottom: 1px solid #f1f1f1;
}

.bd-top_solid_f1 {
  border-top: 1px solid #f1f1f1;
}

.bd-left_groove {
  border-left: 1px groove #1e1e2f;
}

.bd-right_groove {
  border-right: 1px groove #1e1e2f;
}

.bd-corners_left_10 {
  border-radius: 10px 0 0 10px;
}

.bd-corners_right_10 {
  border-radius: 0 10px 10px 0;
}

.bd-corners_top_10 {
  border-radius: 10px 10px 0 0;
}

.bd-corners_top_11 {
  border-radius: 5px 5px 0 0;
}

.bd-corners_top_25 {
  border-radius: 25px 25px 0 0;
}

.bd-radius_2 {
  border-radius: 2px;
}

.bd-radius_4 {
  border-radius: 4px;
}

.bd-radius_5 {
  border-radius: 5px;
}

.bd-radius_8 {
  border-radius: 8px;
}

.bd-radius_10 {
  border-radius: 10px;
}

.bd-radius_25 {
  border-radius: 25px;
}

.bd-right_none {
  border-right: none;
}

.bd-left_none {
  border-left: none;
}

.bd-bottom_1 {
  border-bottom: 1px solid;
}

.box-shadow_1 {
  box-shadow: 0 0 50px 30px rgb(34 34 34 / 5%);
}

.box-shadow_paginate {
  font-size: 19px;
  text-align: center;
  font-weight: 700;
  box-shadow: inset 0 0 4px #eee;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: transparent;
  color: #666;
}

.box-shadow_paginate_dis {
  box-shadow: 0 0 0 0 rgb(34 34 34 / 50%);
}

.min-height {
  min-height: calc(100vh - 145px);
}

.pst-absolute {
  position: absolute;
}

.pst-fixed {
  position: fixed;
}

.pst-relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.top-20 {
  top: 20px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
}

.spin::before {
  animation: 2s linear infinite spinner;
  border: solid 3px #d7d2cc;
  border-bottom-color: #1e1e2f16;
  border-radius: 50%;
  content: "";
  height: 15px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  width: 15px;
  will-change: transform;
}

.spin.login {
  height: 17px;
  top: 8px;
}

.spin.order {
  width: 69px;
  top: 8px;
  height: 17px;
}

.spin.search {
  top: 10px;
  width: 52px;
  height: 19px;
}

.spin.service {
  top: 15px;
}

.spin.service::before {
  border-bottom-color: #EC7211;
  height: 20px;
  width: 20px;
}

textarea {
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 4px;
  border: none;
  background-color: #f1f3f4;
  color: #202124;
}

.paginate {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 50px;
  background: #fff;
  z-index: 40;
  transition: all .5s;
}

.page-current {
  padding-right: 10px;
}

.btn-pag {
  display: flex;
  padding: 5px;
  align-items: center
}

.num-cant {
  padding-left: 10px;
  font-size: 11px;
}

.full-page {
  min-height: 100vh;
  height: auto;
  position: relative;
  top: 0;
}

.nav-bar {
  width: 100%;
  background-color: #234464;
  color: #fff;
  display: flex;
  top: 0;
  position: fixed;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 110;
  transition: all .5s;
}

.list-nav {
  flex-direction: row;
  margin: 0;
  margin-right: 30px;
}

.opt-nav {
  height: 35px;
  display: flex;
  align-items: center;
}

.nav-link:hover {
  border-radius: 8px;
  background: #fff;
  color: #000
}

.nav-link {
  padding: 0px 15px;
  cursor: pointer;
}

.opn {
  display: none;
}

.con-tent {
  position: relative;
  top: 40px;
  z-index: 10;
}

.home-items img {
  width: 100%;
  transition: all .5s;
}


.content-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 50px auto;
  margin-bottom: 100px;
  max-width: 1320px;
}

.cards {
  flex: 1 0;
  width: 100%;
  max-width: 100%;
  min-width: 220px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.cards h3 {
  margin: 8px;
  font-size: 26px;
}

.cards p {
  margin-top: 0;
  margin-bottom: 16px;
}

.grp-svs {
  width: 100%;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}

.default_dg_td {
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  font-size: 14px;
  color: #000;
}

.default_dg_table {
  width: 100%;
}


.list-grp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col-md-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  padding: 0 12px;
}

.col-md-5 {
  flex: 0 0 auto;
  width: 50%;
}

.col-md-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col {
  flex: 1 0;
}

.col-9 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 12px;
}

.default_dg_a_header {
  padding: 7px;
  color: var(--Primmary-color);
  text-decoration: none;
  font-size: 13px;
}


.form-data {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
}

.cnt_itm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  max-width: 1340px;
  margin-right: auto;
  margin-left: auto;
}

.con-tent.pages {
  display: flex;
}

.data-content {
  margin-left: 100px;
}

.list-side {
  max-width: 230px;
  flex-direction: column;
  margin: 0;
  margin-top: 20px;
  padding: 0 10px;
}

.opt-side {
  height: auto;
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.opt-side:hover {
  color: #000000cc;
  border-radius: 8px;
  background: #0000000a;
  transition: background 15ms;
}

.slct-opt {
  color: #fff !important;
  border-radius: 8px !important;
  background: #576576 !important;
  transition: background 15ms !important;
}

.profile-content {
  z-index: 30;
  width: 100%;
  margin-left: 230px;
}

.bttn-scrll {
  display: none;
}

.bttn-scrll1 {
  display: none;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.personal-data {
  margin-top: var(--Hg-Content);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.card-client {
  margin: 10px;
  padding: 10px;
}

.card-balance {
  order: 1;
  position: relative;
  background: linear-gradient(to top right, #2c3e50, #3498db);
  color: white;
  display: flex;
  border-radius: 10px;
  width: 500px;
  font-size: 40px;
  height: 200px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.button-add {
  position: absolute;
  bottom: 0;
  right: 0;
}

.add-fonds {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  margin: 10px;
}

.cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px;
  align-items: center;
  order: 3;
}

.success {
  background: linear-gradient(to top right, #449d44, #1D976C);
  color: white;
}

.rejects {
  background: linear-gradient(to top right, #e52d27, #dc3545);
  color: white;
}

.proccess {
  background: linear-gradient(to top right, #4bafea, #6DD5FA);
  color: white;
}

.news {
  background: linear-gradient(to top right, #f0ad4e, #f0ad4e);
  color: white;
}

.ord-card {
  position: relative;
  margin: 20px;
  width: 250px;
  background: #fff;
  height: 120px;
  font-size: 12px;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  letter-spacing: 1px;
  border-radius: 10px;
}

.space {
  overflow-wrap: anywhere;
  min-width: 100px;
  display: contents;
}

.green {
  width: 75%;
  background: #449d44;
  animation: prog 2s linear;
}

.red {
  width: 40%;
  background: #e52d27;
  animation: prog 2s linear;
}

.blue {
  width: 60%;
  background: #4bafea;
  animation: prog 2s linear;
}

.orange {
  width: 25%;
  background: #f0ad4e;
  animation: prog 2s linear;
}

.status {
  width: 110px;
  height: 40px;
  border-radius: 8px;
  left: 20px;
  position: absolute;
  top: -20px;
  padding-left: 10px;
  padding-top: 7px;
  font-size: 16px;
}




.size-banks {
  width: 720px;
  order: 2;
}

.dg_tr:hover {
  background-color: #f1f1f1;
}

.white-space {
  white-space: normal;
}

.ebcf_modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  padding: 100px 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.ebcf_modal-content {
  background-color: #ffffff;
  margin: auto;
  margin-right: auto;
  border-radius: 5px;
}

.ebcw_modal-content {
  background-color: #ffffff;
  margin: auto;
  margin-right: auto;
  border-radius: 5px;
}


.log {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0 15px;
  font-style: normal;
}

.log:hover {
  border-radius: 8px;
  background: #fff;
  color: #000;
}

.cont-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.crt-order {
  display: flex;
  justify-content: start;
}

.opt-ord {
  position: absolute;
  right: 12px;
  top: 2px;
  font-size: 20px;
}

.opt-ord:hover {
  cursor: pointer;
  border-radius: 10px;
  background: #e5e6e7;
}

.on-opt {
  width: 100px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 50px 15px rgb(34 34 34 / 5%);
  justify-content: space-around;
}

.tx-area {
  width: 95%;
  height: 150px;
  outline: none;
}

.tx-area.comm {
  height: 80px;
  width: 100%;
}

.ords4 {
  color: green;
}

.ords3 {
  color: red;
}

.ebcf_modal-content-orders {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  margin-right: auto;
  border-radius: 5px;
  width: 600px;
  height: 50%;
  overflow: auto;
}

.sub-nav {
  margin: 6px 10px;
  display: var(--Nav-subs);
  padding: 5px;
  color: white;
}

.sub-nav:hover {
  border-radius: 8px;
  background: #fff;
  color: #000;

}

.subs-nav {
  display: flex;
  border-radius: 0 0 8px 8px;
  background: var(--Primmary-color);
  top: 40px;
  min-width: 150px;
  position: absolute;
  flex-direction: column;
}

.new-order {
  width: 60%;
  margin: auto;
  background: #f0ad4e;
  border-radius: 8px;
  color: white;
  font-size: 13px;
}

.proccess-order {
  width: 60%;
  margin: auto;
  background: #4bafea;
  border-radius: 8px;
  color: white;
  font-size: 13px;
}

.reject-order {
  width: 60%;
  margin: auto;
  background: #dc3545;
  border-radius: 8px;
  color: white;
  font-size: 13px;
}

.success-order {
  width: 60%;
  margin: auto;
  background: #449d44;
  border-radius: 8px;
  color: white;
  font-size: 13px;
}

.charge {
  position: absolute;
  bottom: 25px;
  background: #f1f1f1;
  color: black;
  padding: 2px;
  width: 95%;
  border-radius: 10px;
  margin-right: 5px;
}


.charge>span {
  display: block;
  position: relative;
  width: 60%;
  height: 5px;
  border-radius: 8px;
  animation: bar 2s linear;
}

@keyframes bar {
  0% {
    width: 0%;
  }

  100% {
    width: 60%;
  }
}

.spinn {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(#EC7211 0 0) center/0% 100% no-repeat;
  animation: l 1s linear infinite alternate;
}

@keyframes l {
  to {
    background-size: 100% 100%
  }
}

.footer {
  position: relative;
  background: var(--Primmary-color);
  color: white;
  bottom: 0;
}

.dropDown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  padding: 20px;
  right: 0;
  font-size: 13px;
  max-width: 220px;
  margin-left: -40px;
  border-radius: 0 0 0 8px;
  background: var(--Primmary-color);
}

.dropDown span {
  margin: 1px;
  margin-top: 10px;
}

.tb:last-child {
  margin-bottom: 100px;
}

.dropdown {
  display: none;
}

.dropdown-list .dropdown {
  position: absolute;
  top: 100%;
  z-index: 1010;
  background: white;
  border: 1px solid #aaa;
  width: 100%;
  border-top: 0;
  margin-top: 0;
  border-color: #e0e0e0;
  background-clip: padding-box;
  display: block;
}

.dropdown-simple a span {
  height: auto;
  font-size: 15px;
  padding: 5px;
  padding-left: 15px;
  border: 1px solid #dadce0;
  white-space: normal;
  position: relative;
  border-radius: 10px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  line-height: 24px;
}


.results-dropdown {
  list-style: none;
  max-height: 500px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
}

.results-dropdown .group-list span {
  font-size: 14px;
  color: #000;
  margin: 0;
  display: list-item;
  padding: 10px 16px;
  cursor: default;
  list-style: none;
  line-height: 25px;
  word-wrap: break-word;
  border-bottom: #eee solid 1px;
  font-weight: 800 !important;
}

.result-select {
  padding-left: 25px;
  clear: both;
  display: list-item;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
  white-space: normal;
  list-style: none;
  line-height: 25px;
  word-wrap: break-word;
  border-bottom: #eee solid 1px;
  font-weight: 600 !important;
  font-size: 14px;
}

.result-select:hover {
  background: var(--Second-color);
  color: white;
}

.search-list {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}

.search-input {
  padding: 7px 20px 7px 5px;
  background: #f1f1f1;
  margin: 1px 0;
  width: 100%;
  height: auto;
  outline: 0;
  border: solid 1px #aaa;
  font-size: 1rem;
  line-height: normal;
  border-radius: 0;
}

.select-list-user {
  padding: 5px;
}

.select-list-user:hover {
  border-radius: 8px;
  background: #fff;
  color: var(--Primmary-color);
  cursor: pointer;
}


.arrowText {
  position: relative;
  margin: 0px;
  margin-left: 18px !important;
  margin-right: 15px !important;
  padding-right: 5px;
  white-space: nowrap;
  width: auto;
  font-family: unset;
  height: auto;
  color: white;
  font-size: 14px;
  background: #3498db;
  float: left;
}

.arrowText:before,
.arrowText:after {
  content: " ";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.arrowRight:before,
.arrowRight:after {
  border-bottom: 12px solid transparent;
  border-top: 13px solid transparent;
  top: 0px;
}

.arrowRight:before {
  border-right: 16px solid #3498db;
  left: -16px;
  z-index: 1;
}

.prfl span {
  text-transform: uppercase;
}

.lg-chng {
  text-align: center;
  width: auto;
  margin: 5px 0;
}























@media (max-width: 500px) {

  .content-cards {
    max-width: 460px;
  }

  .content-side {
    background: #fff;
    height: calc(var(--Hg-Content) + 15px);
    border-bottom: 1px solid #dadce0;
  }

  .tx-area {
    height: 200px;
    width: 100%;
  }


  .opt-side {
    white-space: nowrap;
  }

}

@media (max-width: 700px) {

  .nav-bar {
    z-index: 30;
    align-items: baseline;
    height: var(--Hg-Navbar);
    flex-direction: column;
    justify-content: flex-start;
    transition: all .5s;
  }

  .opn {
    font-size: 20px;
    cursor: pointer;
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .list-nav {
    display: var(--Open-nav);
    width: 500px;
    padding: 0;
    margin: 0;
  }

  .opt-nav {
    padding: 5px;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .nav-link {
    padding: 4px 15px;
  }

  .home-items img {
    height: auto;
    transition: all .5s;
  }

  .content-cards {
    max-width: 660px;
  }

  .content-list {
    display: flex;
  }

  .list-side {
    max-width: none;
    margin-top: 10px;
    overflow-x: auto;
    margin-left: 40px;
    margin-right: 40px;
  }

  .list-side::-webkit-scrollbar {
    width: 0;
  }

  .profile-content {
    margin-top: var(--Hg-Content);
    margin-left: 0px;
    margin-right: 0px;
  }

  .opt-side {
    height: auto;
    margin-right: 10px;
    overflow-wrap: break-word;
    padding: 10px;
  }

  .bttn-scrll {
    display: block;
    position: absolute;
    left: 0;
    top: calc(var(--Hg-Content) / 2.5);
    font-size: 25px;
  }

  .bttn-scrll1 {
    display: block;
    position: absolute;
    right: 0;
    top: calc(var(--Hg-Content) / 2.5);
    font-size: 25px;
  }

  .paginate {
    left: 20px;
  }

  .content-side {
    z-index: 31;
    background: #ffffff;
    height: calc(var(--Hg-Content) + 15px);
    border-bottom: 1px solid #dadce0;
  }

  .log {
    display: block;
    padding: 4px 15px;
  }

  .dropDown {
    display: block;
    position: static;
    padding: 10px;
    padding-left: 40px;
    max-width: none;
    margin: 0;
  }

  .tx-area {
    height: 200px;
    width: 100%;
  }

  .ebcf_modal-content-orders {
    background-color: #fefefe;
    width: 90%;
  }

  .opt-nav {
    flex-direction: column;
    align-items: baseline;
    position: relative;
    height: auto;
  }

  .nav-link {
    width: 100%;
  }

  .subs-nav {
    display: flex;
    background: none;
    margin-left: 40px;
    width: 100%;
    position: relative;
    flex-direction: column;
    top: 0;
  }

  .dg-nowrap {
    white-space: nowrap;
  }

  .ebcf_modal-content {
    width: 95%;
  }

  .col-md-5.menu>div {
    flex-direction: row;
  }

  .spinn {
    top: calc(var(--Hg-Content) + 55px);
  }

}



@media (max-width: 1024px) {

  .home-items img {
    height: auto;
    transition: all .5s;
  }

  .content-cards {
    max-width: 980px;
  }

  .card-balance {
    width: 410px;
  }

  .size-banks {
    width: 427px;
  }

  .new-order {
    width: 100%;
  }

  .proccess-order {
    width: 100%;
  }

  .reject-order {
    width: 100%;
  }

  .success-order {
    width: 100%;
  }

  /* .col-md-5.menu {
    width: 70%;
  }

  .col-md-5.contact {
    width: 30%;
  } */

}

@media (max-width: 1200px) {

  .col-md-5.menu>div {
    flex-direction: column;
  }

  .col-md-5.menu>div>div {
    margin-top: 10px;
  }

}









/*Min ---------*/


@media (min-width: 200px) {
  .col-md-5.menu {
    width: 100%;
  }

  .col-md-5.lang {
    width: 50%;
  }

  .col-md-5.contact {
    width: 100%;
  }

  .col-md-5.contact>div {
    flex-direction: column;
    align-items: flex-start;
  }

  .col-md-5.contact>div>* {
    margin-top: 5px;
  }

  .col-md-5.contact>div>textarea {
    width: 200px;
  }

}


@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .col-md-5.menu {
    width: 100%;
  }

  .col-md-5.lang {
    width: 50%;
  }

  .col-md-5.contact {
    width: 100%;
  }

  .col-md-5.contact>div {
    flex-direction: column;
    align-items: flex-start;
  }

  .col-md-5.contact>div>* {
    margin-top: 5px;
  }

}


@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-md-5.menu {
    width: 30%;
  }

  .col-md-5.lang {
    width: 20%;
  }

  .col-md-5.contact {
    width: 50%;
  }

}

@media (min-width: 992px) {
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .container {
    max-width: 960px;
  }

  .col-md-5.menu {
    width: 20%;
  }

  .col-md-5.lang {
    width: 10%;
  }

  .col-md-5.contact {
    width: 70%;
  }

  .col-md-5.contact>div {
    flex-direction: row;
  }

}

@media (min-width: 1200px) {

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .container {
    max-width: 1140px;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-5.menu {
    width: 40%;
  }

  .col-md-5.lang {
    width: 10%;
  }

  .col-md-5.contact {
    width: 50%;
  }

}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }


}